body {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items horizontally center */
  justify-content: center; /* Align items vertically center */
  min-height: 100vh; /* Set minimum height to the viewport height */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default paddings */
  box-sizing: border-box; /* Include paddings and borders in the element's total width and height */
}

.App {
  margin-top: .5rem;
  margin-bottom: .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; /* Align text in the center */
}

.App-logo {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.App-header {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.App-logo-pic {
  max-width: 100%;
  width: 10rem;
  margin-bottom: -1rem;
}

.App-main {
  margin: 0 auto;
  white-space: pre-wrap;
  text-align: left;
}

.App-footer {
  padding: 20px;
  flex-grow: 1;
  margin-bottom: 3rem;
}

:root {
  --hover-color: initial;
}

.App-gen-button:hover {
  background-color: var(--hover-color) !important;
}

.App-add-button:hover {
  background-color: var(--hover-color) !important;
}

.App-fav-button:hover {
  background-color: #C7234C
}

.App-info-button:hover {
  background-color: #129DFA
}

.App-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.App-overlay-header {
  text-align: center;
  margin-top: -1.8rem;
  margin-bottom: 1rem;
}

.App-favorites-container {
  border-radius: 15px;
  padding: .75rem;
  max-height: 70%;
  overflow-y: auto;
}

.App-favorites-container ul {
  list-style-type: disc;
  list-style-position: outside;
  text-align: end;
  max-height: 65%;
  border-radius: 15px;
  margin-bottom: 0;
  margin-top: .5rem;
}

.App-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}